import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from '~/styles/global'
import theme from '~/styles/theme'
import { pageview, showCustomConsoleLog } from '~/lib/utils'

const isProd = process.env.NEXT_PUBLIC_API_ENV === 'production'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  if (isProd) showCustomConsoleLog()

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProd) pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return (
    <ThemeProvider theme={theme}>
      <Component {...pageProps} />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default MyApp
