import jwt from 'jsonwebtoken'
import moment from 'moment'

export const generateToken = (): string => jwt.sign({ date: moment().toISOString() }, 'd3d0b7c2-205e-4d93-9d27-3fa6db998e3e')
// check with backend how we'll do this

declare global {
  interface Window {
    dataLayer: any
  }
}

export const GA_TRACKING_ID = 'GTM-N7QGVNZ'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL): void => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'page_view',
      eventModel: {
        page_title: window.document.title,
        page_location: window.location.href,
        page_path: url,
        send_to: GA_TRACKING_ID,
      },
    })
  }
}

type GTagEvent = {
  action: string
  category: string
  label: string
  value: number
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent): void => {
  if (window && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    })
  }
}

/* eslint-disable */
// source: https://www.geradorcnpj.com/javascript-validar-cnpj.htm

export const isCNPJValid = (cnpj: string) => {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj == '') return false

  if (cnpj.length != 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma = Number(numeros.charAt(tamanho - i)) * pos-- + soma
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != Number(digitos.charAt(0))) return false

  tamanho += 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += Number(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado != Number(digitos.charAt(1))) return false

  return true
}

export const isCPFValid = (cpf: any) => {
  if (!cpf) return false
  cpf = cpf.replace(/[^\d]+/g, '')

  var soma = 0
  var resto

  if (cpf == '00000000000') return false
  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11

  if (resto == 10 || resto == 11) resto = 0
  if (resto != parseInt(cpf.substring(9, 10))) return false

  soma = 0
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11

  if (resto == 10 || resto == 11) resto = 0
  if (resto != parseInt(cpf.substring(10, 11))) return false
  return true
}

export const isEmailValid = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const showCustomConsoleLog = () => {
  console.log(
    '%cProvi',
    'background-color:#2647D7; border-radius: 5px; font-weight: bold; font-size: 50px;color: white; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113);' +
      'padding-left: 100px;padding-right: 120px; padding-bottom: 50px;padding-top: 50px; margin: 15px;'
  )

  console.log(
    '%cQuer participar do nosso time de engenharia? Junte-se a nós:  \nhttps://vempraprincipia.vagas.solides.com.br                                       ',
    'background-color:#2647D7; color: #ffffff ; padding: 4px ; font-size: 12px; font-weight: bold;'
  )
}
