import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/Montserrat-Regular.ttf) format('truetype');
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(/fonts/Montserrat-Medium.ttf) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(/fonts/Montserrat-SemiBold.ttf) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(/fonts/Montserrat-Bold.ttf) format('truetype');
    font-display: swap;
  }




  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body:not(.input-radio) {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    font: 400 16px Montserrat, sans-serif;
  }
  .input-radio{
    * {
      box-sizing: content-box;
    }
  }
`
