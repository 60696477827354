import { theme as proviTheme } from '@provi/provi-components'

const theme = {
  provi: proviTheme,
  colors: {
    background: '#ffffff',
    text: '#1c1c1e',
    bluePrimary: '#2647D7',
  },
  footer: {
    foreground: '#ffffff',
    background: '#00198A',
  },
}

export default theme
